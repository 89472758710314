import * as React from 'react';
import { observer } from 'mobx-react';

import { SvgIcon } from '../components/svg-icon';
import logo from '../assets/svg/pushpay-logo.svg';
import { WebGivingPushpayInfo, WebGivingMerchant } from '../webgiving-generated';
import { WebGivingUserAction } from '../webgiving-user-actions';
import { ModalType } from '../modals/modal-type';
import { classNames } from '../../Shared/utils/classnames';
import { injectWebGivingProps, InjectableWebGivingProps, raiseAction } from '../utils/injectable-props';
import { getIconScale, getSupportedCardIconParams } from '../utils/card-utils';
import { TermsAndConditionsFooter } from '../components/terms-and-conditions';
import { SupportedCardBrands } from '../components/supported-card-brands/supported-card-brands';
import { Text } from '../components/text';

import {
	footer,
	footerContainer,
	footerLink,
	orgInfo,
	separator,
	pushpayInfoWrapper,
	pushpayLogoWrapper,
	pushpayLogo,
	info,
	component,
	horizontalSeparator,
	footerBrands,
	footerBrandsTitle,
	footerBrandsWrapper
} from './webgiving-main.less';

export interface IFooterProp {
	merchant: WebGivingMerchant;
	className?: string;
	pushpayInfo: WebGivingPushpayInfo;
	showSupportedPaymentMethods?: boolean;
	showPayerCoverFeesLegalText: boolean;
}

@injectWebGivingProps
@observer
export class WebGivingMainFooter extends React.Component<IFooterProp & InjectableWebGivingProps> {
	render() {
		const { merchant, pushpayInfo, className, showPayerCoverFeesLegalText, showSupportedPaymentMethods } = this.props;
		const {
			HelpCenterLink: helpCenterLink,
		} = pushpayInfo;
		const {
			LegalName: legalName,
			Address: address,
			TradingName: tradingName,
			HasCustomTermsOrPrivacy: hasCustomLinks,
			SupportedCardBrandKeys,
			SupportsApplePay,
			SupportsAch,
		} = merchant;
		return (
			// tslint:disable:max-line-length
			<FooterContainer className={className}>
				{showSupportedPaymentMethods && (
					<FooterSupportedPaymentMethods
						SupportedCardBrandKeys={SupportedCardBrandKeys}
						SupportsApplePay={SupportsApplePay}
						SupportsAch={SupportsAch}
					/>
				)}
				<TermsAndConditionsFooter tradingName={tradingName} hasCustomLinks={hasCustomLinks}/>
				{showPayerCoverFeesLegalText && <p><Text>*This rate represents an approximation of the applicable processing rates; actual processing costs may be higher or lower.</Text></p>}
				<div className={orgInfo}>
					{legalName && <p><strong><Text>Organization Legal Name</Text></strong>: {legalName}</p>}
					{(legalName && address) && <span className={separator}>|</span>}
					{address && <p><strong><Text>Address</Text></strong>: {address}</p>}
				</div>
				<FooterSeparator />
				<FooterPushpaySection helpCenterLink={helpCenterLink} />
			</FooterContainer>
			// tslint:enable:max-line-length
		);
	}

	openPushpayTerms = () => {
		raiseAction(new WebGivingUserAction.OpenModalDialog(ModalType.PushpayTerms), this.props);
	}
}

@observer
export class FooterContainer extends React.Component<{ className?: string}, {}> {
	render() {
		const { className, children} = this.props;
		return(
			<footer className={classNames(footer, className)}>
			<div className={classNames(footerContainer, 'container')}>
				{children}
			</div>
		</footer>
		);
	}
}

export const FooterSeparator = () => <hr className={horizontalSeparator} />;

export const FooterPushpaySection = observer(({ helpCenterLink }: { helpCenterLink: string }) => {
	return(
		<div className={pushpayInfoWrapper}>
			<div className={pushpayLogoWrapper}>
				<SvgIcon className={classNames(pushpayLogo, component)} svg={logo} />
			</div>
			<div className={info}>
				<a className={classNames(footerLink, component)} href={helpCenterLink} target="_blank" rel="noopener noreferrer"><Text>Help Center</Text></a>
				<span className={separator}>|</span>
				<div className={component}>&copy; {new Date().getFullYear()} Pushpay<sup>&reg;</sup> <Text>Ltd, All rights reserved</Text></div>
			</div>
		</div>
	);
});

export const FooterSupportedPaymentMethods: React.FC<{
	SupportedCardBrandKeys: string[];
	SupportsApplePay?: boolean;
	SupportsAch?: boolean;
}> = ({ SupportedCardBrandKeys, SupportsApplePay, SupportsAch }) => {
	const supportedCardStyling = getSupportedCardIconParams();

	return (
		<div className={footerBrands}>
			<span className={footerBrandsTitle}>
				<Text>Accepted payment methods</Text>
			</span>
			<SupportedCardBrands
				brandKeys={SupportedCardBrandKeys}
				iconParams={{
					...supportedCardStyling,
					...(SupportsApplePay ? { applepay: { scale: getIconScale('applepay', true) } } : {}),
				}}
				wrapperClassName={footerBrandsWrapper}
				applePay={SupportsApplePay}
				ach={SupportsAch}
			/>
		</div>
	);
};
