import * as React from 'react';
import { SvgIcon, SvgProperties } from '../svg-icon';
import { CardBrandConfig } from '../../webgiving-generated';
import { icon, brandIcon } from './supported-card-brands.less';
import { baseCardBrandIconsPath, getCardBrandsFromKeys, cardBrandIconsPath } from '../../utils/card-utils';

import cardBrandIcons from '../card-brand-icons';
import { classNames } from '../../../Shared/utils/classnames';

const baseIconParams = { className: '', scale: 1 };

type CardStyling = { scale?: number; className?: string };
type CardBrandStyleProps = { [key: string]: CardStyling };

export class SupportedCardBrands extends React.Component<{
	brandKeys: string[];
	wrapperClassName?: string;
	ach?: boolean;
	applePay?: boolean;
	iconParams?: CardBrandStyleProps;
}> {
	render() {
		// cardBrandIcons are not loaded during test.
		if (!cardBrandIcons.keys().length) {
			return null;
		}

		if (NewFeatures.ApplePay_WebGiving) {
			const { ach, applePay } = this.props;
			const iconParams = this.props.iconParams;
			const supportedBrands = getCardBrandsFromKeys(this.props.brandKeys);

			const bankParams = { ...baseIconParams, ...(iconParams ? iconParams['ach'] : {}) };
			const applePayParams = { ...baseIconParams, ...(iconParams ? iconParams['applepay'] : {}) };

			return (
				<div className={this.props.wrapperClassName}>
					{supportedBrands.map((x) => {
						const style = { ...baseIconParams, ...(iconParams ? iconParams[x.key] : {}) };

						return (
							<SvgIcon
								className={classNames(brandIcon, style.className)}
								svg={getBrandIconInfo(x, cardBrandIconsPath)}
								scale={style.scale}
								title={x.friendlyName}
								key={x.key}
							/>
						);
					})}
					{ach && (
						<SvgIcon
							className={classNames(brandIcon, bankParams.className)}
							svg={getBrandIconInfo({ shortName:'bank' } as CardBrandConfig, cardBrandIconsPath)}
							scale={bankParams.scale}
							title="bank"
							key="bank"
						/>
					)}
					{applePay && (
						<SvgIcon
							className={classNames(brandIcon, applePayParams.className)}
							svg={getBrandIconInfo({ shortName:'applepay' } as CardBrandConfig, cardBrandIconsPath)}
							scale={applePayParams.scale}
							title="applepay"
							key="applepay"
						/>
					)}
				</div>
			);
		} else {
			const supportedBrands = getCardBrandsFromKeys(this.props.brandKeys);
			return (
				<div>
					{supportedBrands.map((x) => (
						<SvgIcon
							className={icon}
							svg={getBrandIconInfo(x)}
							scale={0.75}
							title={x.friendlyName}
							key={x.key}
						/>
					))}
				</div>
			);
		}
	}
}

function getBrandIconInfo(cardBrand: CardBrandConfig, assetPath: string = baseCardBrandIconsPath): SvgProperties {
	if (NewFeatures.ApplePay_WebGiving) {
		const [iconKey] = cardBrandIcons
			.keys()
			.filter((x) => x.toLowerCase().indexOf(`${assetPath}${cardBrand.shortName.toLowerCase()}.svg`) !== -1);

		if (!iconKey) {
			throw new Error(
				`Card icon for ${cardBrand.shortName} cannot be found at ${assetPath}[name].svg, Please add a missing svg.`
			);
		}

		return (cardBrandIcons(iconKey) as any).default as SvgProperties;
	} else {
		const [iconKey] = cardBrandIcons
			.keys()
			.filter((x) => x.toLowerCase().indexOf(`paymentmethod-${cardBrand.shortName.toLowerCase()}.svg`) !== -1);

		if (!iconKey) {
			throw new Error(`Card icon for ${cardBrand.shortName} cannot be found. Please add a missing svg.`);
		}

		return (cardBrandIcons(iconKey) as any).default as SvgProperties;
	}
}
