import * as React from 'react';
import { observer } from 'mobx-react';
import { WebGivingUserAction } from '../webgiving-user-actions';
import { ModalType } from '../modals/modal-type';
import { injectWebGivingProps, InjectableWebGivingProps, raiseAction } from '../utils/injectable-props';
import { footerLink as footerLinkOld } from '../main/webgiving-main.less';
import { footerLink } from '../main/footer/footer.less';
import { Fragment } from '../../Shared/components/fragment';
import { PaymentLabel } from '../webgiving-generated';
import { Text } from '../components/text';

@observer
export class TermsAndConditionsBody extends React.Component<{
	hasCustomLinks: boolean;
	legalName: string;
	paymentLabel: PaymentLabel;
}, {}> {
	render() {
		const { hasCustomLinks, legalName, paymentLabel } = this.props;

		// tslint:disable:max-line-length
		return (
			<p>
				<Text>By making this {paymentLabel.NounLowerCase}</Text>{
					!hasCustomLinks && <Fragment><Text> to </Text><strong>{legalName}</strong></Fragment>
				} <Text>I agree to</Text> {
					hasCustomLinks && <Fragment><strong><Text params={{legalName}}>{`{{legalName}} 's `}</Text></strong><FooterModalLink data-pp-at-target="Merchant Terms" modalType={ModalType.MerchantTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink data-pp-at-target="MerchantPrivacy" modalType={ModalType.MerchantPrivacy}><Text>Privacy Policy</Text></FooterModalLink><Text>, including </Text></Fragment>
				}
				<Text>the Pushpay </Text><FooterModalLink data-pp-at-target="Pushpay Terms" modalType={ModalType.PushpayTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink data-pp-at-target="Pushpay Privacy" modalType={ModalType.PushpayPrivacy}><Text>Privacy Policy</Text></FooterModalLink>
			</p>
		);
		// tslint:enable:max-line-length
	}
}

@observer
export class TermsAndConditionsFooter extends React.Component<{
	hasCustomLinks: boolean;
	tradingName: string;
}, {}> {
	render() {
		const { hasCustomLinks, tradingName } = this.props;

		// tslint:disable:max-line-length
		return (
			<p>
				Pushpay <FooterModalLink data-pp-at-target="Pushpay Terms" modalType={ModalType.PushpayTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink data-pp-at-target="Pushpay Privacy" modalType={ModalType.PushpayPrivacy}><Text>Privacy Policy</Text></FooterModalLink>.
				{ hasCustomLinks && <Fragment><br />{tradingName} <FooterModalLink data-pp-at-target="Merchant Terms" modalType={ModalType.MerchantTerms}><Text>Terms &amp; Conditions</Text></FooterModalLink><Text> and </Text><FooterModalLink data-pp-at-target="MerchantPrivacy" modalType={ModalType.MerchantPrivacy}><Text>Privacy Policy</Text></FooterModalLink>.</Fragment> }
			</p>
		);
		// tslint:enable:max-line-length
	}
}

interface IFooterModalLinkProps {
	modalType: ModalType;
}

@injectWebGivingProps
@observer
class FooterModalLink extends React.Component<IFooterModalLinkProps & InjectableWebGivingProps> {
	render() {
		return <a href="#" className={NewFeatures.WebGiving_UIUpdates ? footerLink : footerLinkOld} onClick={this.handleClick}>{this.props.children}</a>;
	}

	private handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		const { modalType } = this.props;
		raiseAction(new WebGivingUserAction.OpenModalDialog(modalType), this.props);
	}
}
