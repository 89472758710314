import * as React from 'react';
import { observer } from 'mobx-react';
import { transform } from './brand.parameterised.less';
import { Theme } from '../webgiving-generated';
import tinycolor from 'tinycolor2';

@observer
export class BrandingTheme extends React.Component<{ theme: Theme }> {
	render() {
		return <style nonce={this.props.theme.Nonce}>{transform(getTheme(this.props.theme))}</style>;
	}
}

function getTheme({ PrimaryColor, SecondaryColor }: Theme) {
	const primary = tinycolor(PrimaryColor);
	const primaryLighten = tinycolor.mix(primary, 'white', 15);
	const secondary = tinycolor(SecondaryColor);
	const secondaryLighten = tinycolor.mix(secondary, 'white', 15);

	// If white text is unreadable on the lighen 15% color (too light) - switch to darken 15%
	// readablity is determined by WCAG guidelines
	// https://github.com/bgrins/TinyColor#isreadable
	return {
		brandPrimaryColor: PrimaryColor,
		brandPrimaryColorHover: tinycolor.isReadable('#fff', primaryLighten, { level: 'AAA', size: 'small' })
			? primaryLighten.toHexString()
			: tinycolor.mix(primary, 'black', 15).toHexString(),
		brandSecondaryColor: SecondaryColor,
		brandSecondaryColorAlt: tinycolor.isReadable('#fff', secondaryLighten, { level: 'AAA', size: 'small' })
			? secondaryLighten.toHexString()
			: tinycolor.mix(secondary, 'black', 15).toHexString(),
	};
}
