/*
 * This file is automatically generated.
 * Do not add changes in here.
 */

import React, { useCallback } from 'react';
import { useTranslationI18next, TransI18next, TransPropsI18next, UseTranslationOptions } from '@pushpay/i18n';

export type TranslationLanguage = {
	common: {
		English: never;

		Give: never;

		Hi: never;

		Spanish: never;

		'Your Account': never;

		Today: never;

		previousMonth: never;

		nextMonth: never;

		January: never;

		February: never;

		March: never;

		April: never;

		May: never;

		June: never;

		July: never;

		August: never;

		September: never;

		October: never;

		November: never;

		December: never;

		Sunday: never;

		Monday: never;

		Tuesday: never;

		Wednesday: never;

		Thursday: never;

		Friday: never;

		Saturday: never;

		Sun: never;

		Mon: never;

		Tue: never;

		Wed: never;

		Thu: never;

		Fri: never;

		Sat: never;

		' and ': never;

		' We have just sent a text to your mobile device with a link to download': never;

		' for ': never;

		' for {{tradingName}}.': {
			tradingName: string | number;
		};

		' has been set up.': never;

		' on ': never;

		' {{futureGiftLabel}}': {
			futureGiftLabel: string | number;
		};

		'{{merchant}} only supports {{supportedCardBrandNames}}': {
			merchant: string | number;
			supportedCardBrandNames: string | number;
		};

		'{{merchantName}} only accepts debit cards for giving.': {
			merchantName: string | number;
		};

		'{{merchantName}} prefers debit cards for giving but also accepts other supported cards.': {
			merchantName: string | number;
		};

		' {{merchantName}} was successful. ': {
			merchantName: string | number;
		};

		' {{merchantName}} is processing. ': {
			merchantName: string | number;
		};

		' {{merchantName}} was scheduled successfully. ': {
			merchantName: string | number;
		};

		' {{merchantName}} has been set up. ': {
			merchantName: string | number;
		};

		' {{merchantName}} . ': {
			merchantName: string | number;
		};

		' towards {{fundName}} for {{tradingName}}.': {
			fundName: string | number;
			tradingName: string | number;
		};

		' towards {{fundName}} for {{tradingName}}': {
			fundName: string | number;
			tradingName: string | number;
		};

		' towards {{fundName}}': {
			fundName: string | number;
		};

		' was successful.': never;

		'-- Choose {{dropDownLabel}} --': {
			dropDownLabel: string | number;
		};

		'-- Choose Fund --': never;

		'-- Choose Giving Type --': never;

		'1st & 15th monthly': never;

		'Amount exceeds your {{currency}} {{limit}} Fastmode daily limit. ': {
			currency: string | number;
			limit: string | number;
		};

		'Account number': never;

		'Account type': never;

		'Add 2.2% to contribute to processing costs*': never;

		'Add new payment method': never;

		Address: never;

		'After number of donations': never;

		'After number of gifts': never;

		'After number of payments': never;

		'Back to app': never;

		'Bank account': never;

		'By making this donation': never;

		'By making this gift': never;

		'By making this payment': never;

		'By making this donation to {{tradingName}} I agree to the Pushpay Terms & Conditions and Privacy Policy': {
			tradingName: string | number;
		};

		'By making this gift to {{tradingName}} I agree to the Pushpay Terms & Conditions and Privacy Policy': {
			tradingName: string | number;
		};

		'By making this payment to {{tradingName}} I agree to the Pushpay Terms & Conditions and Privacy Policy': {
			tradingName: string | number;
		};

		[`By enabling Fastmode, you'll be able to give (up to double of your average/highest gift from the last 90 days) without a confirmation code. To receive a confirmation code each time, turn Fastmode off.`]: never;

		[`By enabling Fastmode, you'll be able to give (up to double of your average/highest donation from the last 90 days) without a confirmation code. To receive a confirmation code each time, turn Fastmode off.`]: never;

		[`By enabling Fastmode, you'll be able to give (up to double of your average/highest payment from the last 90 days) without a confirmation code. To receive a confirmation code each time, turn Fastmode off.`]: never;

		CVV: never;

		Card: never;

		'Card (Not available)': never;

		'Card number': never;

		'Change bank account details': never;

		'Change details': never;

		'Change your number': never;

		'Changed your mobile number': never;

		Checking: never;

		[`Choosing this option reduces the number of times you're asked to enter your number on this device.`]: never;

		Confirm: never;

		'Confirm Your Donation': never;

		'Confirm Your Gift': never;

		'Confirm Your Payment': never;

		'Confirm your account number': never;

		'Date cannot be in the past': never;

		'Debit card': never;

		'Debit card (Not available)': never;

		'Did you know we have a mobile app? ': never;

		'Do you want to set up a recurring donation?': never;

		'Do you want to set up a recurring gift?': never;

		'Do you want to set up a recurring payment?': never;

		Donate: never;

		'Donate {{amount}}': {
			amount: string | number;
		};

		'Donate again': never;

		'Donate as a guest without creating an account': never;

		'Donate as guest': never;

		'Donate one time': never;

		'Donate with an email & password': never;

		'Donation of ': never;

		'Donation processing ...': never;

		'Donate to {{tradingName}}': {
			tradingName: string | number;
		};

		'Donation type': never;

		'Don’t see your country listed?': never;

		'Email address': never;

		'End date': never;

		'End date must be 1 day after start date or later': never;

		'End date must be before January 1, 2100': never;

		Ending: never;

		'Enter the security code sent to your number': never;

		'Enter your name and email to create an account.': never;

		'Enter your number so we can send you a security code.': never;

		'Enter your number so we can sign you in.': never;

		'Every 2 weeks': never;

		'Every month': never;

		'Every 3 months': never;

		'Every 6 months': never;

		'Every week': never;

		'Every year': never;

		Expiry: never;

		Expiration: never;

		'ENABLE FASTMODE': never;

		'Fastmode daily limit is ': never;

		'Find out more about this event and stay connected through ': never;

		'First name': never;

		'For your security, please only enter your credit card details into credit card fields.': never;

		Frequency: never;

		'From our App': never;

		Fund: never;

		'Get the app': never;

		'Gift of ': never;

		'Gift processing ...': never;

		'Gift type': never;

		'Give {{amount}}': {
			amount: string | number;
		};

		'Give again': never;

		'Give as guest': never;

		'Give as a guest without creating an account': never;

		'Give one time': never;

		'Give to {{tradingName}}': {
			tradingName: string | number;
		};

		'Give with an email & password': never;

		'Giving Type': never;

		'Go back to confirm donation': never;

		'Go back to confirm gift': never;

		'Go back to confirm payment': never;

		'Go to App': never;

		'Hi, {{person}}': {
			person: string | number;
		};

		'Help Center': never;

		'How does mobile sign in work?': never;

		'I agree to': never;

		'I authorize {{tradingName}} to debit the amount shown above from my account {{nickname}} ({{reference}}).': {
			tradingName: string | number;
			nickname: string | number;
			reference: string | number;
		};

		[`I authorize {{tradingName}} to debit the amount shown above (on today's date) from my account {{nickname}} ({{reference}}).`]: {
			tradingName: string | number;
			nickname: string | number;
			reference: string | number;
		};

		'I authorize {{tradingName}} to debit {{nickname}} ({{reference}}) according to the amount and schedule above and the terms below.': {
			tradingName: string | number;
			nickname: string | number;
			reference: string | number;
		};

		[`If the above noted donation date falls on a weekend or holiday, I understand that the donation may be executed on the next business day. I understand that this authorization will remain in effect until I cancel it and that any changes in my account information or termination of this authorization must be made from within my Pushpay.com account or Pushpay mobile App at least 2 business days prior to the next donation date. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I will not dispute {{tradingName}}'s recurring donation with my bank so long as the transactions corresponds to the terms indicated in this agreement.`]: {
			tradingName: string | number;
		};

		[`If the above noted gift date falls on a weekend or holiday, I understand that the gift may be executed on the next business day. I understand that this authorization will remain in effect until I cancel it and that any changes in my account information or termination of this authorization must be made from within my Pushpay.com account or Pushpay mobile App at least 2 business days prior to the next gift date. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I will not dispute {{tradingName}}'s recurring gift with my bank so long as the transactions corresponds to the terms indicated in this agreement.`]: {
			tradingName: string | number;
		};

		[`If the above noted payment date falls on a weekend or holiday, I understand that the payment may be executed on the next business day. I understand that this authorization will remain in effect until I cancel it and that any changes in my account information or termination of this authorization must be made from within my Pushpay.com account or Pushpay mobile App at least 2 business days prior to the next payment date. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I will not dispute {{tradingName}}'s recurring payment with my bank so long as the transactions corresponds to the terms indicated in this agreement.`]: {
			tradingName: string | number;
		};

		'If you have an account already': never;

		'If you wish to sign in via email & password, you will need to create a password. This can be achieved by using the': never;

		'Invalid Preconfigured Fund': never;

		[`It's been too long since you started this donation.`]: never;

		[`It's been too long since you started this gift.`]: never;

		[`It's been too long since you started this payment.`]: never;

		'Last name': never;

		'Keep your account secure by verifying {{emailAddress}}, from the receipt we sent you. No longer using this email?': {
			 emailAddress : string | number;
		};

		'Listen to this podcast and stay connected through ': never;

		'Ltd, All rights reserved': never;

		'Manage Account': never;

		'Mobile number': never;

		'Mobile sign in streamlines your giving by allowing you to sign in with a security code. The way it works is simple. We will text the security code to your mobile phone, or we can send it via voice call.': never;

		More: never;

		'Need help?': never;

		[`Never forget to give by adding a recurring gift. It's convenient and quick to set up!`]: never;

		Next: never;

		'Number of donations': never;

		'Number of gifts': never;

		'Number of payments': never;

		'On a specific date': never;

		'Only whole numbers are accepted': never;

		'Organization Legal Name': never;

		[`Our system encountered an unexpected error. We're currently looking into it.`]: never;

		[`Our system encountered an unexpected error. We're currently looking into it. Please try again soon.`]: never;

		[`Our system encountered an unexpected error. We're currently looking into it. Please ensure your details are correct.`]: never;

		'PAYMENT METHOD': never;

		'PERSONAL DETAILS': never;

		Pay: never;

		'Pay {{amount}}': {
			amount: string | number;
		};

		'Pay {{tradingName}}': {
			tradingName: string | number;
		};

		'Pay again': never;

		'Pay as a guest without creating an account': never;

		'Pay as guest': never;

		'Pay one time': never;

		'Pay with an email & password': never;

		'Payment method': never;

		'Payment of ': never;

		'Payment processing ...': never;

		'Payment type': never;

		'Please check amount again or insert security code to continue.': never;

		'Please add a new payment method.': never;

		'Please check the entered details.': never;

		'Please enter {{field}}': {
			field: string | number;
		};

		'Please enter {{minLength}} characters for {{field}}': {
			minLength: string | number;
			field: string | number;
		};

		'Please enter between {{minLength}} and {{maxLength}} characters for {{field}}': {
			minLength: string | number;
			maxLength: string | number;
			field: string | number;
		};

		'Please enter valid details': never;

		'Please enter email address': never;

		'Please enter first name': never;

		'Please enter last name': never;

		'Please re-enter your details.': never;

		'Please re-enter your gift.': never;

		'Please select a different payment method.': never;

		'Please try again.': never;

		'Please choose an option for {{field}}': {
			field: string | number;
		};

		'Please choose an option for giving type': never;

		'Please confirm your account number for ': never;

		'Please enter a 4-17 digit account number': never;

		'Please enter a 6 digit security code': never;

		'Please enter a 9 digit routing number': never;

		'Please enter a card number': never;

		'Please enter a CVV': never;

		'Please enter mobile number': never;

		'Please enter an amount': never;

		'Please enter an expiration date': never;

		'Please enter postcode': never;

		'Please enter your account number again.': never;

		'Please enter zip code': never;

		'Please choose an option for {{fundName}}': {
			fundName: string | number;
		};

		'Please provide a date': never;

		'Please provide a number between 1 and 1000': never;

		'Please provide a starting date': never;

		'Please provide an end date': never;

		'Please select this option to authorize this payment method.': never;

		'Please specify the frequency': never;

		'Postal Code': never;

		Postcode: never;

		'Privacy Policy': never;

		'Privacy Policy for {{company}}': {
			company: string | number;
		};

		'Preferred payment method is debit card but credit cards are also accepted.': never;

		'Preferred payment methods include debit cards and bank accounts but credit cards are also accepted.': never;

		'Receive a security code by voice call': never;

		'Recent Podcast': never;

		'Recent Video': never;

		'Recurring donations are not supported when donating as a guest': never;

		'Recurring gifts are not supported when giving as a guest': never;

		'Recurring payments are not supported when paying as a guest': never;

		'Repeats 1st & 15th monthly / First donation on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats 1st & 15th monthly / First donation on {{startDate}} / Ending after {{number}} donation': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats 1st & 15th monthly / First donation on {{startDate}} / Ending after {{number}} donations': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats 1st & 15th monthly / First donation on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats 1st & 15th monthly / First gift on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats 1st & 15th monthly / First gift on {{startDate}} / Ending after {{number}} gift': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats 1st & 15th monthly / First gift on {{startDate}} / Ending after {{number}} gifts': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats 1st & 15th monthly / First gift on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats 1st & 15th monthly / First payment on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats 1st & 15th monthly / First payment on {{startDate}} / Ending after {{number}} payment': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats 1st & 15th monthly / First payment on {{startDate}} / Ending after {{number}} payments': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats 1st & 15th monthly / First payment on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every 2 weeks / First donation on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every 2 weeks / First donation on {{startDate}} / Ending after {{number}} donation': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every 2 weeks / First donation on {{startDate}} / Ending after {{number}} donations': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every 2 weeks / First donation on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every 2 weeks / First gift on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every 2 weeks / First gift on {{startDate}} / Ending after {{number}} gift': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every 2 weeks / First gift on {{startDate}} / Ending after {{number}} gifts': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every 2 weeks / First gift on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every 2 weeks / First payment on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every 2 weeks / First payment on {{startDate}} / Ending after {{number}} payment': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every 2 weeks / First payment on {{startDate}} / Ending after {{number}} payments': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every 2 weeks / First payment on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every week / First donation on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every week / First donation on {{startDate}} / Ending after {{number}} donation': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every week / First donation on {{startDate}} / Ending after {{number}} donations': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every week / First donation on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every week / First gift on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every week / First gift on {{startDate}} / Ending after {{number}} gift': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every week / First gift on {{startDate}} / Ending after {{number}} gifts': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every week / First gift on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every week / First payment on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every week / First payment on {{startDate}} / Ending after {{number}} payment': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every week / First payment on {{startDate}} / Ending after {{number}} payments': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every week / First payment on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every month / First donation on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every month / First donation on {{startDate}} / Ending after {{number}} donation': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every month / First donation on {{startDate}} / Ending after {{number}} donations': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every month / First donation on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every month / First gift on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every month / First gift on {{startDate}} / Ending after {{number}} gift': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every month / First gift on {{startDate}} / Ending after {{number}} gifts': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every month / First gift on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every month / First payment on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every month / First payment on {{startDate}} / Ending after {{number}} payment': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every month / First payment on {{startDate}} / Ending after {{number}} payments': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every month / First payment on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every year / First donation on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every year / First donation on {{startDate}} / Ending after {{number}} donation': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every year / First donation on {{startDate}} / Ending after {{number}} donations': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every year / First donation on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every year / First gift on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every year / First gift on {{startDate}} / Ending after {{number}} gift': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every year / First gift on {{startDate}} / Ending after {{number}} gifts': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every year / First gift on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats every year / First payment on {{startDate}} ': {
			startDate: string | number;
		};

		'Repeats every year / First payment on {{startDate}} / Ending after {{number}} payment': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every year / First payment on {{startDate}} / Ending after {{number}} payments': {
			startDate: string | number;
			number: string | number;
		};

		'Repeats every year / First payment on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Repeats 1st & 15th monthly ': never;

		'Repeats every 2 weeks ': never;

		'Repeats every week ': never;

		'Repeats every month ': never;

		'Repeats every 3 months ': never;

		'Repeats every 6 months ': never;

		'Repeats every year ': never;

		'/ First donation on {{startDate}} ': {
			startDate: string | number;
		};

		'/ First donation on {{startDate}} / Ending after {{number}} donation': {
			startDate: string | number;
			number: string | number;
		};

		'/ First donation on {{startDate}} / Ending after {{number}} donations': {
			startDate: string | number;
			number: string | number;
		};

		'/ First donation on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'/ First gift on {{startDate}} ': {
			startDate: string | number;
		};

		'/ First gift on {{startDate}} / Ending after {{number}} gift': {
			startDate: string | number;
			number: string | number;
		};

		'/ First gift on {{startDate}} / Ending after {{number}} gifts': {
			startDate: string | number;
			number: string | number;
		};

		'/ First gift on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'/ First payment on {{startDate}} ': {
			startDate: string | number;
		};

		'/ First payment on {{startDate}} / Ending after {{number}} payment': {
			startDate: string | number;
			number: string | number;
		};

		'/ First payment on {{startDate}} / Ending after {{number}} payments': {
			startDate: string | number;
			number: string | number;
		};

		'/ First payment on {{startDate}} / Ending on {{date}}': {
			startDate: string | number;
			date: string | number;
		};

		'Resend code': never;

		'Resend security code': never;

		'Reset password': never;

		'Routing number': never;

		Savings: never;

		'Schedule recurring donation': never;

		'Schedule recurring gift': never;

		'Schedule recurring payment': never;

		'Security code': never;

		'Select end date': never;

		'Send code': never;

		'Send code via voice call': never;

		'Send me a reminder to review my payment each year': never;

		'Send me a reminder to review my gift each year': never;

		'Send me a reminder to review my donation each year': never;

		'Set a new Password': never;

		'Set up a recurring donation of': never;

		'Set up a recurring gift of': never;

		'Set up a recurring payment of': never;

		'Set up recurring': never;

		'Sign in via email & password': never;

		'Sign into your account': never;

		'Sign out': never;

		'Sorry, an error occurred with your payment. Please check all the details and try again.': never;

		'Sorry, it appears this card has expired. Please check the details or try another card.': never;

		'Sorry, there may be insufficient funds in order to validate this card.': never;

		'Sorry, there was an error with your payment method. Please try again.': never;

		'Sorry, we had an issue processing your card. Please try again later.': never;

		'Sorry, we ran into some trouble while processing your gift. Please check your inbox to verify if this gift was successful. If you do not receive a gift confirmation within 5 minutes, please try again.': never;

		'Sorry, we ran into some trouble while processing your donation. Please check your inbox to verify if this donation was successful. If you do not receive a donation confirmation within 5 minutes, please try again.': never;

		'Sorry, we ran into some trouble while processing your payment. Please check your inbox to verify if this payment was successful. If you do not receive a payment confirmation within 5 minutes, please try again.': never;

		[`Sorry, your card doesn't appear to be valid or supported by Pushpay. Please check the details and try again.`]: never;

		'Sorry, your card was declined. Please contact your bank to resolve the issue.': never;

		Starting: never;

		'Starting date cannot be in the past': never;

		'Stay signed in': never;

		'Still need help?': never;

		Supported: never;

		'Supported payment method is bank account only.': never;

		'Supported payment method is debit card only.': never;

		'Supported payment methods include credit cards and debit cards.': never;

		'Supported payment methods include credit cards, debit cards and bank accounts.': never;

		'Supported payment methods include debit cards and bank accounts.': never;

		'Terms & Conditions': never;

		'Terms and Conditions': never;

		'Terms and Conditions for {{company}}': {
			company: string | number;
		};

		'Thank you for your generosity!': never;

		[`Thank you! We've received your donation.`]: never;

		[`Thank you! We've received your gift.`]: never;

		[`Thank you! We've received your payment.`]: never;

		'Thank you. Your donation is still processing.': never;

		'Thank you. Your gift is still processing.': never;

		'Thank you. Your payment is still processing.': never;

		'The {{field}} is not valid': {
			field: string | number;
		};

		'The first donation will be made on {{date}}.': {
			date: string | number;
		};

		'The first gift will be made on {{date}}.': {
			date: string | number;
		};

		'The first payment will be made on {{date}}.': {
			date: string | number;
		};

		'The donation will be processed on {{date}} and a gift receipt will be sent to your email address {{emailAddress}}.': {
			date: string | number;
			emailAddress: string | number;
		};

		'The fund you have been directed to is not valid. Please select another fund to {{action}} to and contact an administrator at {{company}}.': {
			action: string | number;
			company: string | number;
		};

		'The system is currently too busy. Please try again later.': never;

		'This card number is not valid': never;

		'This CVV is not valid': never;

		'This email address is not valid': never;

		'This expiration date is in the past': never;

		'This expiration date is not valid': never;

		'This mobile number you entered has already been registered.': never;

		'This name is not valid': never;

		'This number is not valid': never;

		'This option is only available if you have donated through Pushpay before.': never;

		'This option is only available if you have given through Pushpay before.': never;

		'This option is only available if you have paid through Pushpay before.': never;

		'This postcode is not valid': never;

		'*This rate represents an approximation of the applicable processing rates; actual processing costs may be higher or lower.': never;

		'This routing number is not valid': never;

		'This security code has already been used, please request a new code': never;

		'This security code has expired': never;

		'This security code is not valid': never;

		'To keep your account secure, use this option only on secure personal devices.': never;

		'To see more and stay connected with us, visit ': never;

		'Your gifts in the last 24 hours have exceeded your {{currency}} {{limit}} Fastmode daily limit. ': {
			currency: string | number;
			limit: string | number;
		};

		'Your donations in the last 24 hours have exceeded your {{currency}} {{limit}} Fastmode daily limit. ': {
			currency: string | number;
			limit: string | number;
		};

		'Your payments in the last 24 hours have exceeded your {{currency}} {{limit}} Fastmode daily limit. ': {
			currency: string | number;
			limit: string | number;
		};

		'Upcoming Event': never;

		'Update mobile': never;

		'Update email address': never;

		'VERIFY YOUR MOBILE NUMBER': never;

		'Verify your mobile number': never;

		'Via text message': never;

		'Via voice call': never;

		'Visit Help Center': never;

		'We can only send you a code every 20 seconds.': never;

		'Welcome to Your Account': never;

		'We understand that sometimes there can be issues with receiving text messages. Please select an option from the list below if you are experiencing any issues.': never;

		[`We'll call you with a 6-digit security code.`]: never;

		[` We'll send an email to {{emailAddress}} when the donation has been processed.`]: {
			emailAddress: string | number;
		};

		[` We'll send an email to {{emailAddress}} when the gift has been processed.`]: {
			emailAddress: string | number;
		};

		[` We'll send an email to {{emailAddress}} when the payment has been processed.`]: {
			emailAddress: string | number;
		};

		[`We've sent a donation receipt to your email address {{emailAddress}}.`]: {
			emailAddress: string | number;
		};

		[`We've sent a gift receipt to your email address {{emailAddress}}.`]: {
			emailAddress: string | number;
		};

		[`We've sent a payment receipt to your email address {{emailAddress}}.`]: {
			emailAddress: string | number;
		};

		[`We've sent a text message to your mobile with a 6-digit security code.`]: never;

		When: never;

		'When. {{date}}': {
			date: string | number;
		};

		'When I cancel it': never;

		'You have authorized a donation of': never;

		'You have authorized a gift of': never;

		'You have authorized a payment of': never;

		'You no longer need to remember a password! The security code replaces the need to remember one. Managing your account and giving has never been so secure.': never;

		[`You will need to sign in to your account using your email and password and then update your mobile number. If you don't have a password already you can create one by using the`]: never;

		[`You'll receive a donation receipt to your email address {{emailAddress}} when the donation is processed.`]: {
			emailAddress: string | number;
		};

		[`You'll receive a gift receipt to your email address {{emailAddress}} when the gift is processed.`]: {
			emailAddress: string | number;
		};

		[`You'll receive a payment receipt to your email address {{emailAddress}} when the payment is processed.`]: {
			emailAddress: string | number;
		};

		[`You've successfully signed out.`]: never;

		'Your Account is where you can view transactions, update your payment method or manage recurring donations.': never;

		'Your Account is where you can view transactions, update your payment method or manage recurring gifts.': never;

		'Your Account is where you can view transactions, update your payment method or manage recurring payments.': never;

		'Your account numbers do not match.': never;

		'Your account is locked due to too many failed sign in attempts. Please unlock it by': never;

		'Your administrators were updating their settings.': never;

		'Your amount must be between ${{minPaymentAmount}} and ${{maxPaymentAmount}}': {
			minPaymentAmount: string | number;
			maxPaymentAmount: string | number;
		};

		[`Your card's CVV is a 3 or 4 digit number, usually on the back next to your signature`]: never;

		'Your donation of': never;

		'Your donation has failed due to an error with your payment method.': never;

		'Your donation is still processing, please give it a few more seconds': never;

		'Your gift has failed due to an error with your payment method.': never;

		'Your gift is still processing, please give it a few more seconds': never;

		'Your payment is still processing, please give it a few more seconds': never;

		'Your payment has failed due to an error with your payment method.': never;

		'Your gift of': never;

		'Your payment of': never;

		'Your recurring donation of': never;

		'Your recurring donation would end too far in the future.': never;

		'Your recurring gift of': never;

		'Your recurring gift would end too far in the future.': never;

		'Your recurring payment of': never;

		'Your recurring payment would end too far in the future.': never;

		'Your session has expired.': never;

		'Zip Code': never;

		'accepts bank transfers only.': never;

		'accepts debit cards and bank transfers only.': never;

		'accepts debit cards and credit cards.': never;

		'accepts debit cards, credit cards and bank transfers.': never;

		', including ': never;

		app: never;

		'e.g. 1-1000': never;

		'every 2 weeks': never;

		'every month': never;

		'every week': never;

		donation: never;

		gift: never;

		'only accepts debit cards for donating.': never;

		'only accepts debit cards for giving.': never;

		'only accepts debit cards for paying.': never;

		payment: never;

		'prefers debit cards for donating but also accepts other supported cards.': never;

		'prefers debit cards for giving but also accepts other supported cards.': never;

		'prefers debit cards for paying but also accepts other supported cards.': never;

		'is your fastest, most convenient way to make a donation.': never;

		'is your fastest, most convenient way to make a gift.': never;

		'is your fastest, most convenient way to make a payment.': never;

		'link.': never;

		'link. Please note, this only applies if you have already created an account.': never;

		or: never;

		'prefers debit cards or bank transfers for donating but also accepts other supported cards.': never;

		'prefers debit cards or bank transfers for giving but also accepts other supported cards.': never;

		'prefers debit cards or bank transfers for paying but also accepts other supported cards.': never;

		'setting a new password.': never;

		' to ': never;

		to: never;

		'the Pushpay ': never;

		[`{{legalName}} 's `]: {
			legalName: string | number;
		};

		'{{dropDownLabel}} (optional)': {
			dropDownLabel: string | number;
		};

		'{{dropDownLabel}}': {
			dropDownLabel: string | number;
		};

		'{{name}}': {
			name: string | number;
		};

		'Recurring donation ': never;

		'Recurring gift ': never;

		'Recurring payment ': never;

		'Scheduled donation ': never;

		'Scheduled gift ': never;

		'Scheduled payment ': never;

		'Your session has expired. Please refresh to re-authenticate.': never;

		Cryptocurrency: never;

		'Other ways to donate': never;

		'Other ways to give': never;

		'Other ways to pay': never;

		'All gifts will be converted to USD.': never;

		'All donations will be converted to USD.': never;

		'All payments will be converted to USD.': never;

		'Click here ': never;

		'Engiven ': never;

		'Engiven is a trusted partner of Pushpay. ': never;

		'Make your Crypto gift': never;

		'Make your Crypto donation': never;

		'Make your Crypto payment': never;

		[`To make your gift, click the button below to be redirected to Engiven's website, enter your gift amount and fill out the form.`]: never;

		[`To make your donation, click the button below to be redirected to Engiven's website, enter your donation amount and fill out the form.`]: never;

		[`To make your payment, click the button below to be redirected to Engiven's website, enter your payment amount and fill out the form.`]: never;

		'We have partnered with Engiven to make it easy to give with cryptocurrency.': never;

		'We have partnered with Engiven to make it easy to donate with cryptocurrency.': never;

		'We have partnered with Engiven to make it easy to pay with cryptocurrency.': never;

		apply: never;

		'terms and conditions ': never;

		'to learn more about giving in cryptocurrency.': never;

		'to learn more about donating in cryptocurrency.': never;

		'to learn more about paying in cryptocurrency.': never;

		'We have partnered with Engiven to make it easy to give with stock.': never;

		'We have partnered with Engiven to make it easy to donate with stock.': never;

		'We have partnered with Engiven to make it easy to pay with stock.': never;

		'to learn more about giving in stock.': never;

		'to learn more about donating in stock.': never;

		'to learn more about paying in stock.': never;

		'Make your Stock gift': never;

		'Make your Stock donation': never;

		'Make your Stock payment': never;

		'How would you like to give?': never;

		'How would you like to donate?': never;

		'How would you like to pay?': never;

		Stocks: never;

		'Engiven terms and conditions apply': never;

		'Credit Card / ACH': never;

		'Card / ACH': never;

		'Card / ACH / Apple Pay': never;

		[`The time limit has expired. We'll call you with a 6-digit security code.`]: never;

		[`The time limit has expired. We've sent a text message to your mobile with a 6-digit security code.`]: never;

		'Sorry, it appears there were some problems with processing your card. Please update the payment method and try again.': never;

		'Sorry, your password was incorrect. Please try again.': never;

		'Sorry, your passcode was incorrect. Please try again.': never;

		'Sorry, please sign in and try again.': never;

		'Sorry, it appears this transaction may be a duplicate - please check your email to find out if it was successful': never;

		'Sorry, the amount can not be changed once you have attempted payment': never;

		'Sorry, we did not receive a confirmation from the payment service within the expected time. Check your transaction history to verify the outcome of this payment.': never;

		'Your payment has already been completed. There is nothing else for you to do.': never;

		'Your payment has failed to be completed in time': never;

		'You payment has been cancelled': never;

		'Your payment is underway and will be transferred out of your bank account within a couple of days. There is nothing else for you to do.': never;

		'Your payment can not be completed. It is associated with a different Pushpay user account.': never;

		'This organization can only accept payments for bills or invoices that are sent directly to you. Please contact the merchant to be able to receive and pay your bills via Pushpay quickly.': never;

		'To complete this payment you must provide a valid street and city for your profile. Please update your profile, or choose a different payment method, then try again.': never;

		'Payer details are invalid': never;

		'Sorry, your card was declined. Please contact your bank.': never;

		'We are having difficulty processing your transaction. Please re-enter your payment details and try again.': never;

		'Please make sure all the reference fields are entered and are correct.': never;

		'Card expired. Please retry with updated card information.': never;

		'Please ensure the date of next payment is valid.': never;

		'Please ensure the set frequency of this schedule is valid.': never;

		'Payment Schedule no longer exists': never;

		'End Date must be greater then the start date': never;

		'End Occurrences must be greater then 0': never;

		'IP Address must be provided for a recurring payment': never;

		'Your recurring schedule would end too far in the future. Please check the entered details.': never;

		'Supported Payment methods': never;

		'accepts Apple Pay, debit cards, credit cards and bank transfers.': never;

		'prefers debit cards or bank transfers for donating but also accepts Apple Pay and other supported cards.': never;

		'prefers debit cards or bank transfers for paying but also accepts Apple Pay and other supported cards.': never;

		'prefers debit cards or bank transfers for giving but also accepts Apple Pay and other supported cards.': never;

		'accepts Apple Pay, debit cards and bank transfers only.': never;

		'accepts Apple Pay, debit cards and credit cards.': never;

		'prefers debit cards for donating but also accepts Apple Pay and other supported cards.': never;

		'prefers debit cards for giving but also accepts Apple Pay and other supported cards.': never;

		'prefers debit cards for paying but also accepts Apple Pay and other supported cards.': never;

		'only accepts Apple Pay and debit cards for donating.': never;

		'only accepts Apple Pay and debit cards for paying.': never;

		'only accepts Apple Pay and debit cards for giving.': never;

		Or: never;

		'First names cannot start with special characters': never;

		'Last names cannot start with special characters': never;

		'Accepted payment methods': never;
	};
};

export type NamespaceKey = keyof TranslationLanguage;

export type Translate<TNamespaceKey extends NamespaceKey> = <TKey extends keyof TranslationLanguage[TNamespaceKey]>(
	key: TKey,
	...params: TranslationLanguage[TNamespaceKey][TKey] extends never
		? [undefined?]
		: [TranslationLanguage[TNamespaceKey][TKey]]
) => string;

/**
 * A utility hook for interacting with i18n. Caters for all basic functionality such as translation, changing language, listening to
 * language change etc.
 *
 * If you want to add translation post processors or require additional functionality eg. translate Moment create an index file with
 * your own hook that calls this hook internally
 */
export function useTranslation<TNamespaceKey extends NamespaceKey>(
	namespace: TNamespaceKey,
	options?: UseTranslationOptions,
	translationPostProcessors: ((text: string) => string)[] = []
) {
	const { t, i18n, ready } = useTranslationI18next(undefined, options);
	const { language, changeLanguage: changeLanguageI18n } = i18n;
	const translate = useCallback<Translate<TNamespaceKey>>(
		function translateInner(key, ...params) {
			const translatedText = t(`${namespace}א${key}`, ...(params as any));
			return translationPostProcessors.reduce((text, processor) => processor(text), translatedText);
		},
		[namespace, language]
	);
	const keyExists = useCallback((key: string | string[]) => i18n.exists(`${namespace}א${key}`), [
		namespace,
		language,
	]);
	const changeLanguage = useCallback(changeLanguageI18n.bind(i18n), [i18n]);
	const onLanguageChange = useCallback(
		(callback: (language: string) => void) => {
			i18n.on('languageChanged', callback);
			return () => i18n.off('languageChanged', callback);
		},
		[i18n]
	);

	return {
		translate,
		keyExists,
		language,
		changeLanguage,
		onLanguageChange,
		i18n,
		ready,
	};
}

export type TransProps<
	TNamespaceKey extends NamespaceKey,
	TKey extends keyof TranslationLanguage[TNamespaceKey]
> = Omit<TransPropsI18next, 'i18nKey' | 'values' | 'ns'> & {
	i18nKey: TKey;
	namespace?: TNamespaceKey;
} & (TranslationLanguage[TNamespaceKey][TKey] extends never
		? { values?: undefined }
		: { values: TranslationLanguage[TNamespaceKey][TKey] });

export const getTransWithDefaultNamespace = <TDefaultNamespace extends NamespaceKey>(
	_defaultNamespace: TDefaultNamespace
) => <TKey extends keyof TranslationLanguage[TNamespaceKey], TNamespaceKey extends NamespaceKey = TDefaultNamespace>({
	namespace,
	i18nKey,
	...rest
}: TransProps<TNamespaceKey, TKey>) => {
	useTranslationI18next();
	return <TransI18next ns={namespace} i18nKey={i18nKey as string} {...rest} />;
};
